@import '../../global/style-guide';

.wishlistWidgetContainer { margin: 0 auto; max-width: 912px; }

.wishlistHeader {
	margin-top: 30px;
	h2 { display: inline-block; font: 700 16px var(--fontHeading); margin-right: 20px; padding-top: 6px; }
	.wishlistSubHeader { 
		float: right;
		> a:first-child { margin-right: 10px; }
	}
}

.wishlistItemsContainer {
	padding: 0;
	.collapsibleItem { height: 160px; }
}